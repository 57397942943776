<template>
    <main>
      <b-overlay :show="loading">
        <validation-observer ref="form">
          <b-card>
              <b-row>
                <b-col sm="12" md="6">
                    <v-select :options="barangOptions" v-model="barang" label="text"></v-select>    
                </b-col>
            </b-row>
          </b-card>
          <!-- two card -->
          <b-row>
            <!-- Penempatan gudang -->
            <b-col sm="12" md="6">
              <b-card>
                <form>
                  <b-form-group v-if="myGudang">
                      <label for="">Pilih Gudang</label>
                      <b-form-input disabled :value="myGudang.nama_gudang"></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="blok"
                      rules="required"
                    >
                      <label for="">Pilih Blok</label>
                      <v-select
                        :options="bloks"
                        v-model="form.blok_id"
                        :required="!form.blok_id"
                        :reduce="(option) => option.value"
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
  
                  <b-form-group>
                    <b-button @click.prevent="addBarang" variant="primary"
                      >Add</b-button
                    >
                  </b-form-group>
                </form>
              </b-card>
            </b-col>
            <!-- Barang yang sudah disimpan -->
            <b-col cols="12">
              <b-card>
                <b-table
                  :fields="fieldsAddedItems"
                  bordered
                  striped
                  responsive
                  :items="addedItems"
                >
                  <template #cell(no)="row">
                    {{ row.index + 1 }}
                  </template>
                  <template #cell(lokasi)="{ item }">
                    <strong>
                      {{ item.lokasi }}
                    </strong>
                  </template>
                  <template #cell(action)="row">
                    <b-link
                      class="text-danger"
                      @click.prevent="addedItems.splice(row.index, 1)"
                    >
                      <feather-icon size="24" icon="XCircleIcon"></feather-icon>
                    </b-link>
                  </template>
                </b-table>
                <div class="d-flex justify-content-end">
                  <b-button variant="primary" @click.prevent="submit">
                    Simpan
                  </b-button>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </validation-observer>
      </b-overlay>
    </main>
  </template>
  <script>
  import {
    BTable,
    BOverlay,
    BFormSelect,
    BFormRadio,
    BLink,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    BButton,
    BFormInput,
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import { extend } from "vee-validate";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "vee-validate/dist/rules";
  extend("required", {
    ...required,
    message: "This field is required",
  });
  extend("positive", (value) => {
    return value > 0;
  });
  
  export default {
    components: {
      vSelect,
      BFormSelect,
      BLink,
      ValidationObserver,
      ValidationProvider,
      BOverlay,
      BFormGroup,
      BFormRadio,
      BTable,
      BRow,
      BCol,
      BCard,
      BFormCheckbox,
      BButton,
      BFormInput,
    },
    watch: {
        selectedBarang: {
            handler(val) {
                if(val.jumlah) {
                    this.form.jumlah = parseInt(val.sisa)
                }
            },
            deep: true
        },
      nilai(value2) {
        let hasil = value2 * this.id_satuan;
        this.form.jumlah = hasil;
      },
      id_satuan(value) {
        let hasil = value * this.nilai;
        this.form.jumlah = hasil;
      },
      "form.gudang_id"(gudang_id) {
        this.getBlok(gudang_id);
      },
      async selectedInvoice(pengadaan) {
        if(pengadaan.data) {
            this.pengadaan = pengadaan
            const {id} = pengadaan.data
            // this.assets = rincian
            this.loading = true
            this.assets = await this.$store.dispatch('asset/getRincianProcurement', {pengadaan_id: id})
            this.loading = false
        }
      },
    },
    data: () => ({
        barang: null,
        barangOptions: [],
      params: {},
      loading: false,
      selectedInvoice: null,
      selectedBarang: null,
      addedItems: [],
      fieldsAddedItems: [
        { key: "no", label: "No" },
        { key: "asset", label: "Nama Aset" },
        { key: "lokasi", label: "Lokasi" },
        { key: "action", label: "#" },
      ],
      fields: [
        { key: "action", label: "#" },
        { key: "asset", label: "Aset" },
        { key: "satuan", label: "Satuan" }
      ],
      bloks: [],
      pengadaan: {
        id: null,
        no: null,
      },
      raks: [],
      lacis: [],
      palets: [],
      gudangs: [],
      assets: [],
      form: {
        pengadaan_id: null,
        gudang_id: null,
        blok_id: null,
        jumlah: 0
      },
      invoiceList: [],
      id_satuan: 0,
      hasil: 0,
      nilai: 0,
    }),
    methods: {
      submit() {
        // return false
        const payloads = []
        this.addedItems.map((item) => {
            payloads.push({
                gudang_id: this.myGudang.id,
                blok_id: item.blok_id,
                asset_id: item.asset_id
            })
        });
  
        this.loading = true;
        this.$store
          .dispatch("asset/savePenyimpananEmpty", payloads)
          .then(() => {
            this.loading = false;
            this.displaySuccess({
              message: "Penyimpanan aset berhasil dibuat!",
            });
  
            setTimeout(() => {
              this.$router.push("/asset-penyimpanan");
            }, 1000);
          })
          .catch((e) => {
            this.loading = false;
            this.displayError(e);
            return false;
          });
      },
      addBarang() {
        this.$refs.form.validate()
        .then((success, failed) => {
          if (success) {
            let data = {};
            Object.assign(data, this.form);
            data.asset =
              this.barang && this.barang
                ? this.barang.text
                : null;
            data.asset_id =
              this.barang && this.barang ? this.barang.value : null;
  
            // get lokasi
            const blok = this.bloks.find((blok) => blok.value == data.blok_id)
  
            let lokasi = blok ? `Blok: ${blok.text}` : ""
            data.lokasi = lokasi;
            data.p_id = this.barang ? this.barang.id : null
            this.addedItems.push(data);
          }
        })
        .catch(e => console.error(e));
      },
      async getBlok(gudang_id) {
        this.bloks = [];
        const params = {
            gudang_id: this.myGudang.id
        }
        const bloks = await this.$store.dispatch('asset/getBlok', params)
        this.bloks = bloks.map(blok => ({value: blok.id, text: blok.blok}))
      },
      async getBarang() {
        const params = {}
        if(this.myGudang) {
            params.gudang_id = this.myGudang.id
        }

        const barangs = await this.$store.dispatch('asset/getBarang', params)
        this.barangOptions = barangs.data.map(item => ({
            value: item.id,
            text: item.nama
        }))
      }
    },
    async created() {
      if(!this.myGudang) {
        this.$router.push('/asset-penyimpanan')
      }
      await this.getBarang()
      this.getBlok()
    },
  };
  </script>